import "./Header.css";
import {
  FaInstagram,
  FaWhatsapp,
  FaViber,
  FaPhoneAlt,
  FaHome,
  FaRegClock,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AiOutlineFacebook } from "react-icons/ai";

export function Header() {
  const navigate = useNavigate();

  const handleLogoClick = (e) => {
    e.preventDefault();
    navigate('/');
  };

  const handleCallClick = () => {
    window.location.href = "tel:+385989029277";
  };

  const handleContactClick = (app) => {
    if (app === "viber") {
      window.location.href = "viber://chat?number=0989029277";
    } else if (app === "whatsapp") {
      window.location.href =
        "https://api.whatsapp.com/send?phone=+385989029277";
    }
  };
  const facebookClick = () => {
    window.open(
      "https://www.facebook.com/profile.php?id=100087110885013",
      "_blank"
    );
  };
  const instagramClick = () => {
    window.open("https://www.instagram.com/robert.logistika/", "_blank");
  };
  

  return (
    <div>
      <div className="container">
        <div className="social-icon">
          <ul className="social-icon__list">
            <li>
              <div onClick={facebookClick}>
                <AiOutlineFacebook size="25px" color="#1778F2"/>
              </div>
            </li>
            <li>
              <div onClick={instagramClick}>
                <FaInstagram size="25px" color="#DD2A7B"/>
              </div>
            </li>
            <li onClick={() => handleContactClick("whatsapp")}>
              <FaWhatsapp size="25px" color="#25D366"/>
            </li>
            <li onClick={() => handleContactClick("viber")}>
              <FaViber size="25px" color="#665CAC"/>
            </li>
          </ul>
        </div>
        <div className="border">
          <div className="square">
            <div className="image" onClick={handleLogoClick}>
              <img src="../assets/logowh.png" alt="logo" />
            </div>
          </div>
        </div>

        <div className="contact">
          <ul className="contact__list">
            <div>
              <li className="click-phone" onClick={handleCallClick}>
                <FaPhoneAlt size="40px" color="#CA2026" />
                <p>+385989029277</p>
                <p>info@robert-logistika.hr</p>
              </li>
            </div>
            <div>
              <li>
                <FaHome size="40px" color="#CA2026" />
                <p>Omladinska ul. 119</p>
                <p>31431 Livana</p>
              </li>
            </div>

            <li>
              <FaRegClock size="40px" color="#CA2026" />
              <p>Ponedjeljak-Petak: 09:00 - 20:00</p>
              <p>Subota: 09:00 - 15:00</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
