import { Footer } from "../../layouts/Footer/Footer";
import { Header } from "../../layouts/Header/Header";
import { DeliverySection } from "../../section/DeliverySection/DeliverySection";
import { ImageSection } from "../../section/ImageSection/ImageSection";
import { Partners } from "../../section/Partners/Partners";
import { ServiceActivities } from "../../section/ServiceActivities/ServiceActivities";

export function HomePage() {
  return (
    <>
      <Header />
      <ImageSection />
      <DeliverySection />
      <ServiceActivities />
      <Partners />
      <Footer />
    </>
  );
}
