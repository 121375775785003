import "./Partners.css";
import data from "./data.json";

export function Partners() {
  return (
    <div>
        <h1>PARTNERI</h1>
      <div className="display">
        {data.map((item) => (
          <div className="box-wrap" key={item.id}>
            <img src={item.img} alt={`Images ${item.id}`} width="200px" />
          </div>
        ))}
      </div>
    </div>
  );
}
