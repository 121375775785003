import "../../section/ImageSection/ImageSection.css"
export function ImageSection() {
  return (
    <div className="container">
      <div className="image-container">
        <img className="responsive-img" src="../assets/images/banner.png" alt="track-warhouse" />
      </div>
    </div>
  );
}
