import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage/HomePage";
import { ContactEmail } from "./pages/ContactEmail/ContactEmail";
import { PrivacyPolice } from "./pages/PrivacyPolice/PrivacyPolice";
import { Cookies } from "./layouts/Cookie/Cookies";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Cookies />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contactpage" element={<ContactEmail />} />
          <Route path="/privacypolice" element={<PrivacyPolice />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
