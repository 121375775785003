import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Input } from "../../UI/Input";
import { Footer } from "../../layouts/Footer/Footer";
import { FaArrowRight } from "react-icons/fa";
import "./ContactEmail.css";

export function ContactEmail() {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleLogoClick = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const ApiKey =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZDdlM2Q4ZDFmOTI5ZWYyNDBmOGYzODcxMGZiMTNhNDhkOWQ0ZjQzMDBjZDlkNDk2NDNiMTY2NzQ3ZDBmODMwNmI2YTQ1NDRiYmY4NDBhMjIiLCJpYXQiOjE2ODAyNjQ2MzAuMDIxNzk1LCJuYmYiOjE2ODAyNjQ2MzAuMDIxNzk3LCJleHAiOjQ4MzU5MzgyMzAuMDE3MTQyLCJzdWIiOiI2NTM0NyIsInNjb3BlcyI6WyJlbWFpbF9mdWxsIiwiZG9tYWluc19mdWxsIiwiYWN0aXZpdHlfZnVsbCIsImFuYWx5dGljc19mdWxsIiwidG9rZW5zX2Z1bGwiLCJ3ZWJob29rc19mdWxsIiwidGVtcGxhdGVzX2Z1bGwiLCJzdXBwcmVzc2lvbnNfZnVsbCIsInNtc19mdWxsIiwiZW1haWxfdmVyaWZpY2F0aW9uX2Z1bGwiLCJpbmJvdW5kc19mdWxsIiwicmVjaXBpZW50c19mdWxsIiwic2VuZGVyX2lkZW50aXR5X2Z1bGwiXX0.Vcks-hhvBMpmgVguSfX_3UZKnm0L5-kqpMZkDEMb8DMdo9zHWOKiv-E_h6SjVeqS5WR9ZmbRtzhZ5lS7kEMq2UbW1nxe8PXZXsAgi14oNj53-G-1lQZHKY6yRJfGZ8geiZd0iPMtaa50SiKyxGcd-Da51eq-sJIE7ft3Y-mrzY8lRekJjeWjKLl6SgVOyxqQyneyt1sQHUuIB89uqRT56JDo90m7_tf5-drgJWnv3dEochYKQ91sdvt4ONcmUV7zlX5IWvweBDncBOpX_pm_Giyy55yU38ZuVpYNeK4Qw4l1iIsM-bRZBq-m8XL7kxWq4XgA-ycCIUw-dOdn-LcidveXvthv5GTkyF82kpzN86D2ElJZTXJSNFlmAHvZ0V9ycieTnpM6R3hX5-QYgwl_jh9a2vyUAk-3SOY33CN5uE9iyK6aDEHTcPlDWYDJ_EEm5WJYBWmCAOATYzUJGC1ZBw6L4RkHfdcS0JBioFGDE4xbaSwmVkw2L5BxkZF2TuBz_gEYYxUeoeqR97vMKaX03BJQnNHw70sb6qbS4v83_JGMPqqyv9gdIHq1gtonc4Jr4UVfyD2epNH4GbJHahbmEIQAem2a_Ze5Ym341n-q6mgRWQlfwO97-CGFth-uoX8yHIboPazzgZGnjhXBoxqKGGrBXIKsBE2IwdmMnLhqKbI";
    
      const data = {
          personalizations: [
            {
              to: [
                {
                  email: "info@robert-logistika.hr",
                },
              ],
              subject: `${subject}`,
            },
          ],
          from: {
            email: 'info@robert-logistika.hr',
            name: `${name}`,
          },
          text: [
            {
              type: "text/html",
              value: `
                  <html>
                    <head>
                      <style>
                        body {
                          font-family: Arial, sans-serif;
                        }
                      </style>
                    </head>
                    <body>
                    <p>${message}</p>
                    <p>Srdačan pozdrav,</p>
                    <p>Ime i Prezime: ${name}</p>
                    <p>Tvrtka/Osoba: ${companyName}</p>
                    <p>E-mail: ${email}</p>
                    <p>Telefon: ${phone}</p>
                    </body>
                  </html>
                `,
            },
          ],
        };
        
    axios
      .post("https://api.mailersend.com/v1/email", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ApiKey}`,
        },
      })
      .then(() => {
        console.log("Poruka uspješno poslana!");
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    
    <div>
      <div className="square">
        <div className="image" onClick={handleLogoClick}>
          <img src="../assets/logowh.png" alt="logo" />
        </div>
      </div>
      <div className="form-content">
      {error ? (
    <div>{error}</div>
  ) : (
        <form onSubmit={handleSubmit} className="form-group">
          <Input
            label="Ime i Prezime*"
            type="text"
            placeholder="Unesite Ime i Prezime"
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            label="Ime tvrtke*"
            type="text"
            placeholder="Unesite naziv tvrtke"
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <Input
            label="E-mail*"
            type="email"
            placeholder="Unesite E-mail"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            label="Telefon"
            type="number"
            placeholder="Unesite broj telefona"
            onChange={(e) => setPhone(e.target.value)}
          />
          <Input
            label="Predmet"
            placeholder="Unesite predmet"
            onChange={(e) => setSubject(e.target.value)}
          />
          <div className="label-form">
            <label>Poruka*</label>
          </div>
          <div className="text-area">
            <textarea
              name="message"
              id="messageId"
              rows="15"
              placeholder="Unesite Vašu poruku"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="button-form">
            <button className="btn-contact" type="submit">
              <span className="btn-text">Pošalji</span>{" "}
              <FaArrowRight className="icon_arrow" size="15px" />
            </button>
          </div>
        </form>
         )}
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
    )}
