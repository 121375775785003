import "./DeliverySection.css";

export function DeliverySection() {
  return (
    <div className="container">
      <div className="content">
        <h1>ROBERT Logistika</h1>
        <div className="cards">
          <div className="card">
            <div className="image-position">
              <img
                className="images"
                src="../assets/images/paleta.png"
                alt="paleta"
              />
            </div>
            <div>
              <h3>PALETNA DOSTAVA</h3>
              <p>
                Prijevoz tereta na paletama omogućuje jednostavniju i
                učinkovitiju dostavu. Standardno se koriste tzv. EURO palete -
                dimenzije 1.200 x 800 x 400mm i nosivosti do 750 kg.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="image-position">
              <img
                className="images"
                src="../assets/images/dostava.png"
                alt="centar"
              />
            </div>
            <div>
              <h3>DOSTAVA IZ TRGOVAČKIH CENTARA</h3>
              <p>
                U mogućnosti smo Vam ponuditi prijevoz kupljenog namještaja ili
                tehničke robe iz bilo kojeg trgovačkog centra na području
                Hrvatske.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="image-position">
              <img
                className="images"
                src="../assets/images/paketi.png"
                alt="paket"
              />
            </div>
            <div>
              <h3>DOSTAVA PAKETA</h3>
              <p>
                RL paket je usluga koja omogućuje brzu i sigurnu dostavu paketa
                po najpovoljnijoj cijeni na tržištu.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="square-bottom"></div>
    </div>
  );
}
