import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import "./Footer.css";

export function Footer() {
  const navigate = useNavigate();

  function handleClick(e) {
    e.preventDefault();
    navigate("/contactpage");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  function handlePrivacyPolicyClick(e) {
    e.preventDefault();
    navigate("/privacypolice");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  const handleCallClickMobile = () => {
    window.location.href = "tel:+385989029277";
  };
  const handleCallClickPhone = () => {
    window.location.href = "tel:+38531280423";
  };
  const handleCallClickMobile2 = () => {
    window.location.href = "tel:+385915151011";
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="company-info">
            <h2>O nama</h2>
            <p>
              ROBERT Logistika j.d.o.o sa sjedištem na Livani uspješno posluje
              od 1.rujna 2020.g. na prostoru Republike Hrvatske. RL grupacija se
              bavi kombi prijevozom, dostavom iz trgovačkih centara i montažom
              namještaja.
            </p>
          </div>
          <div className="bank">
            <h2>Tvrtka</h2>
            <ul>
              <li>Robert Logistika j.d.o.o. za usluge prijevoza</li>
              <li>OIB: 19360102927</li>
              <li>IBAN: HR7623400091111101366</li>
              <li>MBS: 05326664</li>
              <li>PRIVREDNA BANKA d.d. (PBZ)</li>
              <li>Sjedište: Zagreb, Radnička cesta 44</li>
            </ul>
          </div>
          <div className="services">
            <h2>Naše Usluge</h2>
            <ul>
              <li>Dostava iz trgovačkih centara</li>
              <li>Paletna dostava</li>
              <li>Dostava paketa</li>
              <li>Unos kupljene robe</li>
              <li>Montaža namještaja</li>
              <li>Demontaža i pakiranje</li>
            </ul>
          </div>
          <div className="contact-us">
            <h2>Contact Us</h2>
            <ul className="link">
              <li>Email: info@robert-logistika.hr</li>
              <li onClick={handleCallClickPhone}>Ured: +38531280423</li>
              <li onClick={handleCallClickMobile}>Robert: +385989029277</li>
              <li onClick={handleCallClickMobile2}>Montaža: +385915151011</li>
              <li>Adresa: Omladinska ul. 119, 31431 Livana</li>
            </ul>
            <button className="btn-contact" type="button" onClick={handleClick}>
              <span className="btn-text">Pošalji Email</span>{" "}
              <FaArrowRight className="icon_arrow" size="15px" />
            </button>
            <div className="link" onClick={handlePrivacyPolicyClick}>
              POLITIKA PRIVATNOSTI
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; Robert Logistika 2023. Sva prava pridržana.</p>
      </div>
    </footer>
  );
}

export default Footer;
