export function ServiceActivities() {
  return (
    <div className="container">
      <div className="content">
        <h1>RL FURNITURE - Montaža namještaja</h1>
        <div className="cards">
          <div className="card">
            <div className="image-position">
              <img
                className="images"
                src="../assets/images/unos.jpg"
                alt="paleta"
              />
            </div>
            <div>
              <h3>UNOS KUPLJENE ROBE</h3>
              <p>
                U slučaju da nemate rješenje kako unijeti namještaj u stambeni
                prostor - ne brinite!
              </p>
              <p>
                RL tim će se pobrinuti da Vaša roba u najkraćem roku bude u
                prostorijama.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="image-position">
              <img
                className="images"
                src="../assets/images/montaza.png"
                alt="centar"
              />
            </div>
            <div>
              <h3>MONTAŽA NAMJEŠTAJA</h3>
              <p>
                Našim iskusnim monterima ništa ne stvara problem. Oslobodite se
                posla i uživajte bez brige u kupljenom namještaju.
              </p>
              <p>
                Nudimo montaže pločastog namještaja, kuhinja, spavaćih soba i
                ostalog namještaja.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="image-position">
              <img
                className="images"
                src="../assets/images/pakiranje.jpg"
                alt="paket"
              />
            </div>
            <div>
              <h3>DEMONTAŽA I PAKIRANJE</h3>
              <p>
                Ukoliko ste prodali stari namještaj te Vam je potrebna demontaža,
                naši monteri će odraditi posao i upakirati namještaj potpuno
                besplatno da ne bi došlo do oštećenja prilikom transporta.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
